import React from 'react'
import {BrowserRouter, Switch, Route, useLocation} from 'react-router-dom'
import MovementsList from 'application/movements/MovementsList';
import AddMovement from 'application/movements/AddMovement'
import FundsList from 'application/funds/FundsList';
import CategoriesList from 'application/categories/CategoriesList';
import ImportStatement from 'application/movements/ImportStatement';
import Settings from 'application/user/Settings';
import Report from 'application/budget/report/Report';
import Plan from "application/budget/plan/Plan";
import qs from 'qs'
import ClassificationRulesList from './categories/ClassificationRulesList';
import Trends from './budget/trends/Trends';

function useQuery() {
    return qs.parse(useLocation().search.substr(1));
}  

export default function Home(props) {
    let query = useQuery()
    return (
        <BrowserRouter basename={process.env.REACT_APP_FINANZ_PATH}>
            <Switch>
                <Route exact path="/">
                    <Report query={query} url="/report"/>
                </Route>
                <Route path="/movements/import">
                    <ImportStatement />
                </Route>
                <Route path="/movements/add">
                    <AddMovement />    
                </Route>        
                <Route path="/movements">
                    <MovementsList query={query} url="/movements"/>
                </Route>
                <Route path="/funds">
                    <FundsList query={query} url="/funds"/>
                </Route>
                <Route path="/categories">
                    <CategoriesList query={query} url="/categories"/>
                </Route>
                <Route path="/report">
                    <Report query={query} url="/report"/>
                </Route>
                <Route path="/plan">
                    <Plan query={query} url="/plan"/>
                </Route>
                <Route path="/settings">
                    <Settings />
                </Route>
                <Route path="/classification-rules">
                    <ClassificationRulesList query={query} url="/classification-rules"/>
                </Route>
                <Route path="/trend">
                    <Trends />
                </Route>

                <Route path="*">
                    <h1>Not found!</h1>
                </Route>
            </Switch>
        </BrowserRouter>
    );
  }
