import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Form, Col, Row} from 'react-bootstrap'
import {Formik, FieldArray} from 'formik'
import * as yup from 'yup'
import * as Types from 'common/utils/Types'
import { Redirect } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons';


/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 * - entryId: if it's a number, it will edit the movement with this ID. If it's null, it will create a new movement
 */
class EditClassificationRuleWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {

        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}></Redirect>
        }

        const me = this
        const loadingStep = (parameters) => {
            let dataToFetch = {
                "categories": API.get('/categories'),
                "conditionTypes": API.get("/classification-rules/condition-types/v2"),
                "aggregationTypes": API.get("/classification-rules/aggregation-types")
            }
            if(this.props.entryId !== undefined) {
               dataToFetch.entry = API.get(`/classification-rules/${this.props.entryId}`)
            }
            return API.multiple(dataToFetch)
            .then((res) => { 
                return {
                    categories: res.categories.results, 
                    entry: res.entry,
                    conditionTypes: res.conditionTypes.results,
                    aggregationTypes: res.aggregationTypes.results
                } 
            })
        }

        const userInputStep = (parameters, loading_result, values, errors, completion) => {
            const form = this.form(loading_result, values, errors, completion)
            return {component: form, title: this.props.entryId ? "Edit rule" : "New rule"}
        }
        const executionStep = (parameters, loading_result, user_input) => {
            if (this.props.entryId) {
                return API.put(`/classification-rules/${this.props.entryId}`, user_input)
            } else {
                return API.post("/classification-rules", user_input)
            }
        }

        const validationStep = (parameters, loading_result, user_input) => {
            return this.validateForm(user_input)
        }
        const completionCallback = (execution_result) => {
            if(Types.isString(me.props.onSuccess)) {
                me.setState({redirect: me.props.onSuccess})
            } else { // is a callback
                me.props.onSuccess(execution_result && execution_result.data)
            }

        }

        return <OperationWorkflowModal
            static={this.props.static}
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            validationStep={validationStep}
            executionStep={executionStep}
            completionCallback={completionCallback}
        />
    }   

    form(loading_result, prevalidation_values, validation_errors, completion) {

        const parseForm = (values) => {
            completion(values)
        }

        const schema = yup.object().shape({
            name: yup.string().required(),
            categoryId: yup.number().required(),
            aggregation: yup.string().required(),
            conditions: yup.array().of(
                yup.object().shape({
                    type: yup.string().required(),
                    parameter: yup.string().required()
                })
            )
        })

        const defaultCondition = () => { return {
            type: loading_result.conditionTypes[0].name,
            parameter: ""
        }}
        const previousValues = loading_result.entry || prevalidation_values
        const isEdit = !!previousValues
        const initialValues = isEdit ? {
            name: previousValues.name,
            categoryId: previousValues.categoryId || previousValues.category.id,
            aggregation: previousValues.aggregation,
            conditions: previousValues.conditions
        } : {
            name: "",
            categoryId: "",
            aggregation: loading_result.aggregationTypes[0],
            conditions: [defaultCondition()]
        }
        const emptyOption = <option></option>
        
        return <>
        <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type='text'
                        name='name' 
                        placeholder="Name" 
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                        />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formCategory">
                    <Form.Label>Category to assign</Form.Label>
                    <Form.Control 
                        as='select'
                        name='categoryId' 
                        value={values.categoryId}
                        onChange={handleChange}
                        isInvalid={!!errors.categoryId} 
                    >
                        {isEdit ? '' : emptyOption}
                        {loading_result.categories.map((item) => <option value={item.id} key={`cat${item.id}`}>{item.name}</option>)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.categoryId}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formAggregation">
                    <Form.Label>Aggregation function</Form.Label>
                    <Form.Control 
                        as='select'
                        name='aggregation' 
                        value={values.aggregation}
                        onChange={handleChange}
                        isInvalid={!!errors.aggregation} 
                    >
                        {loading_result.aggregationTypes.map(e => { return <option value={e} key={e}>{e}</option> })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.aggregation}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formConditions">
                <Form.Label>Conditions</Form.Label>
                <FieldArray
                    name="conditions"
                    render={(arrayHelper) => (
                        <div>
                            {values.conditions && values.conditions.length > 0 ? (
                                values.conditions.map((c, index) => (
                                    <div className="form-array-entry" key={index}>
                                        <Form.Group as={Row} key={`type_${index}`}>
                                            <Form.Label column xs={3} >Type</Form.Label>
                                            <Col xs={7}>
                                                <Form.Control 
                                                    as='select'
                                                    name={`conditions.${index}.type`} 
                                                    onChange={handleChange}
                                                    value={values.conditions[index].type}
                                                    style={{width: "100%"}}
                                                >
                                                    {loading_result.conditionTypes.map(e => { return <option value={e.name} key={e.name}>{e.description}</option> })}
                                                </Form.Control>
                                            </Col>
                                            {index > 0 ?             
                                            <Col xs="auto">
                                                <Button
                                                    variant="link"
                                                    onClick={() => arrayHelper.remove(index)}
                                                    style={{padding: 0}}
                                                ><Icon.Trash /></Button>
                                            </Col>      
                                            : ''}                     
                                        </Form.Group>
                                        <Form.Group as={Row} key={`params_${index}`} >
                                            <Form.Label column xs={3}>Parameter</Form.Label>
                                            <Col>
                                                <Form.Control 
                                                    name={`conditions.${index}.parameter`} 
                                                    value={values.conditions[index].parameter}
                                                    onChange={handleChange}
                                                    isInvalid={errors.conditions && !!errors.conditions[index]
                                                        && !!errors.conditions[index].parameter}
                                                    />
                                                {validation_errors && validation_errors.rules[index] && validation_errors.rules[index].errors.message ? 
                                                    <div className='form-error'>{validation_errors.rules[index].errors.message}</div> 
                                                    : ''}
                                            </Col>
                                        </Form.Group>
                                        
                                    </div>
                                ))
                            ) : ''}
                            <Button variant="link" onClick={() => arrayHelper.push(defaultCondition())}>
                            Add condition
                            </Button>
                        </div>
                    )}
                />
                <Form.Control.Feedback type="invalid">{typeof errors.conditions === 'string' ? errors.conditions : ''}</Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }

    validateForm(userInput) {
        const rules = userInput.conditions
        if(!rules || rules.length === 0) {
            return Promise.resolve({success: false, errors: {message: "Needs at least one rule"}})
        }

        const apiCalls = rules.map((r, i) => API.post(`/classification-rules/validate-condition`, r).then((resolve) => {
            return {success: resolve.data.success, errors: {message: resolve.data.message}}
        }, (reject) => {
            return {success: false, errors: {message: reject.message}}
        }))
        return Promise.all(apiCalls).then(r => {
            const finalSuccess = r.every(e => e.success)
            return {success: finalSuccess, errors: {message: "One or more rules are invalid", rules: r}}
        })
    }
}

export default EditClassificationRuleWorkflow