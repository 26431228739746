import React, {Component} from 'react'
import LoginForm from './common/components/LoginForm'
import Home from './application/Home'
import Header from "./common/components/Header";
import {Auth} from "./common/logic/Auth";
import {BrowserRouter as Router} from "react-router-dom";
import BigSpinner from './common/components/BigSpinner';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: null,
            credentialsCheckInProgress: false
        }        
        this.loginSuccessful = this.loginSuccessful.bind(this);
    }

    loginSuccessful(username) {
        this.setState({username: username, credentialsCheckInProgress: false})
    }

    componentDidMount() {
        const app = this
        const credentials = Auth.getCredentials()
        if (credentials.username && credentials.password) {
            this.setState({credentialsCheckInProgress: true})
            Auth.validateCredentials(credentials).then(function (valid) {
                app.setState({credentialsCheckInProgress: false})
                app.setState({username: credentials.username})
            }).catch(function (error) {
                app.setState({credentialsCheckInProgress: false})
            })
        } 
    }

    render() {

        const body = this.state.credentialsCheckInProgress ? <BigSpinner /> : 
            (this.state.username ? 
                <Home username={this.state.username}/> :
                <LoginForm loginCallback={this.loginSuccessful} />
            )
        return (
            <Router>
                <div>
                    <div className="app-header">
                        <Header username={this.state.username} />
                    </div>
                    <div className="app-wrapper">
                        {body}
                    </div>
                </div>
            </Router>
        )
    }
}

export default App