import React, {Component} from 'react'

/**
 * Props: 
 * - title
 * - subtitle
 */
class Title extends Component {

    render() {
        return <h1>{this.props.title} {this.props.subtitle && <span className="subtitle">{this.props.subtitle}</span>}</h1>
    }
}

export default Title