import {Alert, Card, CardDeck, Badge, Form, Button} from 'react-bootstrap'
import Title from 'common/components/Title'
import React, {Component} from 'react'
import MonthOfYear from 'common/logic/MonthOfYear'
import API from 'common/logic/API'
import BigSpinner from 'common/components/BigSpinner'
import * as Icons from 'react-bootstrap-icons'
import * as Charts from 'react-chartjs-2';
import Colors from 'common/utils/Colors'
import MonthPicker from 'common/components/MonthPicker'
import Utils from 'common/Utils'

var _ = require('lodash');

class Trends extends Component {

    constructor() {
        super()
        this.state ={
            modal: null
        }        
    }

    calculateMonth() {
        const query = new URLSearchParams(window.location.search)
        const startParam = query.get("start") || ""
        const endParam = query.get("end") || ""
        const startMonth = MonthOfYear.parse(startParam)
        const endMonth = MonthOfYear.parse(endParam)
        const now = MonthOfYear.now()

        let range = {}
        if(startMonth && endMonth) {
            range.start = startMonth
            range.end = endMonth
        } else if(startMonth) {
            range.start = startMonth
            range.end = startMonth.nextYear()
        } else if(endMonth) {
            range.start = endMonth.previousYear()
            range.end = endMonth
        } else {
            range.start = now.previousYear()
            range.end = now
        }

        range.start = range.start.min(now)
        range.end = range.end.min(now)
        range.end = range.end.max(range.start)
        return range
   }

    componentDidMount() {
        const range = this.calculateMonth()
        this.setState({startMonth: range.start, endMonth: range.end}, () => this.fetchData())
    }

    fetchData() {
        const me = this
        this.setState({trend: null})
        API.multiple({
            trend: API.get(`/expense-report/trend?start=${this.state.startMonth.toString()}&end=${this.state.endMonth.toString()}`)
        }).then(response => {
            me.setState({
                trend: response.trend,
            })
        }).catch(error => {
            me.setState({error: error.message})
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    render() {

        if(this.state.error) {
            return <><Title tile="Trend" /><Alert variant="danger">{this.state.error}</Alert></>
        }

        if(!this.state.trend) {
            return <><Title title="Trend"/>
            <BigSpinner /></>
        }    

        const trend = this.state.trend
        const howManyMonths = trend.months.length
        const firstMonth = trend.months[0].month
        const lastMonth = trend.months[howManyMonths - 1].month
        const currencies = _.keys(trend.actualTotal)

        const range = this.calculateMonth()
        const rangeStart = range.start
        const rangeEnd = range.end

        const nextMonths = [rangeStart.nextMonth(), rangeEnd.nextMonth()]
        const prevMonths = [rangeStart.previousMonth(), rangeEnd.previousMonth()]
        
        const subtitle=<>
            <Button 
                onClick={() => this.onSelectMonths(prevMonths)}
                variant="link"
                className="prev"><Icons.ChevronDoubleLeft /></Button>

            From {firstMonth} to {lastMonth} <Button variant="link" onClick={() => this.showMonthSelection()}>
                <Icons.BoxArrowInUpRight />
                </Button>

            <Button 
                onClick={() => this.onSelectMonths(nextMonths)}
                variant="link"
                className="next"><Icons.ChevronDoubleRight /></Button>

            {currencies.length > 1 ? 
                <>Jump to {currencies.map(c => 
                    <span className="inline-link-list" key={c}><a href={`#currency_${c}`}>{c}</a></span>)}</> 
                : ''}
            </>


        let modal = null
        if(this.state.modal) {
            switch(this.state.modal.type) {
                case "picker":
                    modal = this.monthPickerDialog()
                    break
                default:
            }
        }

        return <>
            <Title title={`Income trend`} 
                subtitle={subtitle}
            />
            {modal}
            {currencies.map((c) =>
            <TrendsForCurrency 
                id={`currency_${c}`}
                needsTitle={currencies.length > 1}
                key={c} 
                currency={c} 
                actualTotal={trend.actualTotal[c]}
                expectedTotal={trend.expectedTotal[c]}
                months={trend.months}
            />)}
        </>
    }   

    showMonthSelection() {
        this.setState({modal: {type: "picker"}})
    }

    handleStartMonthChange(e) {
        console.log(e)
    }

    monthPickerDialog() {

        return <MonthPicker
            show={true}
            selectRange={true}
            onClose={() => this.hideDialogs()}
            onSelect={(m) => this.onSelectMonths(m)}
        />
    }

    onSelectMonths(months) {
        this.hideDialogs()
        Utils.pushUrlWithParams({
            start: months[0].toString(),
            end: months[1].toString()
        })
        this.setState({startMonth: months[0], endMonth: months[1]}, () => this.fetchData())
    }
}

/**
 * - needsTitle: Bool
 * - currency
 * - actualTotal: 1000.00
 * - expectedTotal: 1000.00
 * - months: [{ "actual": {}, "expected": {"EUR": "2000.00"}, "month": "2020-08", "planId": 1}, ...]
 */
class TrendsForCurrency extends Component {

    constructor(props) {
        super(props)

        const monthsData = this.props.months.map((m) => (
            {
                actual: parseFloat(m.actual[this.props.currency]) || 0,
                expected: parseFloat(m.expected[this.props.currency]) || 0,
                month: m.month
            } 
        ))

        const firstNonEmptyMonth = monthsData.findIndex(m => m.actual !== 0 || m.expected !== 0)
        const nonEmptyMonthsData =firstNonEmptyMonth > -1 ? monthsData.slice(firstNonEmptyMonth) : monthsData

        let cumulativeSum = 0.0

        const cumulativeAmounts = monthsData.map(m => { 
                cumulativeSum += parseFloat(m.actual)
                return cumulativeSum
        })

        cumulativeSum = 0.0
        const nonEmptyCumulativeAmounts = nonEmptyMonthsData.map(m => { 
            cumulativeSum += parseFloat(m.actual)
            return cumulativeSum
        })

        this.state = {
            showCumulative: false,
            hideEmpty: true,
            monthsData: monthsData,
            nonEmptyMonthsData: nonEmptyMonthsData,
            cumulativeAmounts: cumulativeAmounts,
            nonEmptyCumulativeAmounts: nonEmptyCumulativeAmounts
        }
    }

    render() {

        const diff = this.props.actualTotal - this.props.expectedTotal
        const lessThanExpected = diff < 0
        let percentage = parseFloat(this.props.expectedTotal) !== 0 ? 
            ((diff / this.props.expectedTotal) * 100).toFixed(1) 
            : undefined
        const trendIcon = lessThanExpected ? <Icons.CaretDownFill /> : <Icons.CaretUpFill />

        if(percentage !== undefined && percentage < 0 && this.props.expectedTotal < 0) {
            percentage = -percentage // avoid negative expected create negative percentage when there is a positive income
        }

        const data = this.state.hideEmpty ? this.state.nonEmptyMonthsData : this.state.monthsData
        

        const datasets = [
            {
                data: data.map((m) => m.actual),
                backgroundColor: data.map((m) => Colors.redOrangeByThreshold(0, m.expected, m.actual, 0.3)),
                borderColor: data.map((m) => Colors.redOrangeByThreshold(0, m.expected, m.actual)),
                borderWidth: 1,
                label: "Actual amount"
            },
            {
                data: data.map((m) => m.expected),
                borderWidth: 1,
                label: "Expected"
            }
        ]

        if(this.state.showCumulative) {
            datasets.push({
                data: this.state.hideEmpty ? this.state.nonEmptyCumulativeAmounts : this.state.cumulativeAmounts,
                type: "line",
                label: "Cumulative amount",
                backgroundColor: Colors.somberBlue(0.5),
                borderColor: Colors.somberBlue(0.5),
                fill: false,
                tension: 0.1
            })
        }

        return <>
            {this.props.needsTitle ? <h2 id={this.props.id}>Currency: {this.props.currency}</h2> : null}
            <CardDeck>
                <Card>
                    <Card.Body>
                        <Card.Title><Icons.CurrencyExchange /> Actual amount</Card.Title>
                        <Card.Text className="card-big-amount">
                            {Utils.formatMoney(this.props.actualTotal)} {this.props.currency}{' '}
                            <Badge variant={lessThanExpected ? "danger" : "success"}>{trendIcon}{' '}
                                {percentage !== undefined ? `${percentage} %` : ''} </Badge>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title><Icons.Easel /> Expected amount</Card.Title>
                        <Card.Text className="card-big-amount">
                            {Utils.formatMoney(this.props.expectedTotal)} {this.props.currency}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            <Card>
                <Card.Body>
                    <Form.Check 
                        inline
                        type="checkbox" 
                        label="Show cumulative amount" 
                        checked={this.state.showCumulative} 
                        onChange={(e) => this.cumulativeToggle(e)}/>
                    <Form.Check 
                        inline
                        type="checkbox" 
                        label="Hide empty months" 
                        checked={this.state.hideEmpty} 
                        onChange={(e) => this.hideEmptyToggle(e)}/>
                    <Charts.Bar 
                        data={{
                            datasets: datasets,
                            labels: data.map(m => m.month)
                        }}
                    />
                </Card.Body>
            </Card>
        </>
    }

    hideEmptyToggle(e) {
        this.setState({
            hideEmpty: !this.state.hideEmpty
        })
    }

    cumulativeToggle(e) {
        this.setState({
            showCumulative: !this.state.showCumulative
        })
    }
}

export default Trends