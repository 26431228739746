import API from 'common/logic/API';
import Utils from "common/Utils";

/**
 * Properties:
 * - endpoint
 * - endpointParams
 * - stateHolder: holder of the state to update. The results fetched by this class will be stored in the "results" object
 * Callbacks:
 * - newURLCallback(url): new url that includes the updated start value
 */
class FetchBehavior {

    constructor(props = {}) {
        this.start = 0

        Object.keys(props).forEach(k => {
            this[k] = props[k]
        })

        if(this.stateHolder) {
            this.stateHolder.setState({results: {
                results: null,
                error: null,
                hasMore: false
            }})
        }

        this.fetchValues = this.fetchValues.bind(this)
    }

    setState(state) {
        if (this.stateHolder) {
            const prev = this.stateHolder.state.results || {}
            const newState = {
                ...prev,
                ...state
            }
            this.stateHolder.setState({results: newState})
        }
    }

    fetchFromURL() {
        const query = new URLSearchParams(window.location.search)
        const start = query.get("start") || 0
        const sort = query.get("sort")
        this.fetchValues(start, sort)
    }

    fetchValues(start, sort) {
        if(!this.stateHolder) {
            return
        }
        if (this.fetchStartCallback) {
            this.fetchStartCallback()
        }
        this.setState({results: null})

        let params ={}
        let paramsToDelete = []
        if (start > 0) {
            params.start = start
        } else {
            paramsToDelete.push("start")
        }
        if (sort != null) {
            params.sort = sort
        }
        if (this.newURLCallback) {
            this.newURLCallback(Utils.currentUrlWithParams(params, paramsToDelete))
        }
        const me = this
        API.get(this.endpoint, {...params, ...this.endpointParams})
            .then(function (response) {
                const results = response.data["results"]
                if (results) {
                    me.setState({
                        results: results, 
                        hasMore: response.data.hasMore, 
                        start: response.data.start, 
                        error: null })
                } else {
                    me.setState({error: response.status})
                }
            }).catch(function (error) {
                me.setState({error: error})
            })
    }

}

export default FetchBehavior