
class Colors {

    static bySign(amount, alpha = 1) {
        return (amount < 0) ? this.red(alpha) : this.green(alpha)
    }

    static redOrangeByThreshold(redIfLower, orangeIfLower, amount, alpha = 1) {
        if(amount > orangeIfLower) return this.green(alpha)
        if(amount < redIfLower) return this.red(alpha)
        return this.orange(alpha)
    }

    static green(alpha = 1) {
        return `rgba(51, 153, 51, ${alpha})`
    }

    static red(alpha = 1) {
        return `rgba(231, 37, 37, ${alpha})`
    }

    static orange(alpha = 1) {
        return `rgba(252, 186, 3, ${alpha})`
    }

    static somberBlue(alpha = 1) {
        return `rgba(45, 65, 105, ${alpha})`
    }
}


export default Colors