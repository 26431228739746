import React, {Component} from 'react'
import {Alert, Button, Modal} from 'react-bootstrap'
import BigSpinner from './BigSpinner'

/**
 * performs the following steps:
 * - Initial loading
 * - Ask for confirmation
 * - Ask for user input
 * - Execute
 * - Report result
 * 
 * Props: 
 * - parameters: object with workflow-specific parameters, to be used by following steps
 * - loadingStep: a function (parameters) => loading promise(loading_result). if null
 *    it skips the loading step
 * - confirmationStep: function (parameters, loading_result) => confirmation text. if null
 *    it skips the confirmation step
 * - userInputStep: function (parameters, loading_result, previousValues, validationErrors, completion_callback(user_input)) => {component: ..., title: ...}.
 *    if null, skips the user input step
 * - validationStep: function (parameters, loading_result, user_input) => promise({success: true|false, errors: {...}}). If `errors` contains the 
 *  key "message", it will display it in a error Alert
 * - executionStep: function (parameters, loading_result, user_input) => promise(result)
 * - completionCallback: (execution_result) => (). This function should at least remove this component so that it unmounts. 
 *    the execution result will be `false` when the operation was canceled.
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 *
 */

const ProcessState = Object.freeze({
  "LOAD_INITIAL":1, 
  "WAITING_CONFIRMATION":2,
  "WAITING_USER":3, 
  "VALIDATION": 4,
  "EXECUTING":5, 
  "DONE": 6})

class OperationWorkflowModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
        currentPhase: 0,
    }
    this.userConfirmed = this.userConfirmed.bind(this)
    this.userCanceled = this.userCanceled.bind(this)
  }

  componentDidMount() {
    this.advancePhase()
  }

  setupUserInput() {
    const me = this
    let userInputSetup = this.props.userInputStep(
      this.props.parameters,
      this.state.loadingResult,
      this.state.userInput,
      this.state.validationErrors,
      (input) => {
        me.setState({userInput: input}, () => me.advancePhase())
      }
    )
    this.setState({userInputSetup: userInputSetup})
  }

  setupConfirmation() {
    let confirmationText = this.props.confirmationStep(
      this.props.parameters,
      this.state.loadingResult
    )
    this.setState({confirmationText: confirmationText})
  }

  startLoading() {
    const me = this
    if(!this.props.loadingStep) {
      return this.advancePhase()
    }
    this.props.loadingStep(this.props.parameters)
      .then((result) => {
        me.setState({loadingResult: result})
      })
      .catch((err) => {
        me.setState({
          error: err
        })
      })
      .then(r => {
        me.advancePhase()
      })
  }

  advancePhase(jumpToPhase) {
    if(this.currentPhase === ProcessState.DONE || this.state.error) {
      return
    }
    let newPhase = jumpToPhase !== undefined ? jumpToPhase : this.state.currentPhase +1
    if(newPhase === ProcessState.LOAD_INITIAL) {
      if (!this.props.loadingStep) {
        newPhase = ProcessState.WAITING_CONFIRMATION
      } else {
        this.startLoading()
      }
    }
    if(newPhase === ProcessState.WAITING_CONFIRMATION) {
      if(!this.props.confirmationStep) {
        newPhase = ProcessState.WAITING_USER
      } else {
        this.setupConfirmation()
      }
    }
    if(newPhase === ProcessState.WAITING_USER) {
      if(!this.props.userInputStep) {
        newPhase = ProcessState.EXECUTING
      } else {
        this.setupUserInput()
      }
    }
    if(newPhase === ProcessState.VALIDATION) {
      if(!this.props.validationStep) {
        newPhase = ProcessState.EXECUTING
      } else {
        this.startValidation()
      }
    }
    if(newPhase === ProcessState.EXECUTING) {
      this.startExecution()
    }
    this.setState({currentPhase: newPhase}, () => {
      if(newPhase === ProcessState.DONE) {
        this.props.completionCallback(this.state.executionResult)
      }
    })
  }

  startExecution() {
    const me = this
    this.props.executionStep(
      this.props.parameters,
      this.state.loadingResult,
      this.state.userInput
    ).then((result) => {
      me.setState({executionResult: result}, () => me.advancePhase())
    }).catch((err) => {
      me.setState({error: err.message || err})
    })
  }

  userConfirmed() {
    this.advancePhase()
  }

  userCanceled() {
    this.setState({currentPhase: ProcessState.DONE}, () => this.props.completionCallback(false))
  }

  startValidation() {
    const me = this
    this.props.validationStep(
      this.props.parameters,
      this.state.loadingResult,
      this.state.userInput
    ).then(r => {
      if(!!r && r.success) {
        me.setState({validationErrors: undefined}, () => me.advancePhase())
      } else {
        me.setState({validationErrors: r.errors}, () => me.advancePhase(ProcessState.WAITING_USER))
      }
    })
  }

  render() {  
    if (this.state.currentPhase === ProcessState.DONE) {
      return null
    }

    const showCloseButton = this.props.static ? null : true

    let content = null
    if(this.state.error) {
      content =
        <> 
        <Modal.Header closeButton={showCloseButton}>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{this.state.error.message || this.state.error}</Alert>
        </Modal.Body>
        </>
    } else {
      switch(this.state.currentPhase) {
        case ProcessState.LOAD_INITIAL:
          content = <BigSpinner></BigSpinner>
          break
        case ProcessState.WAITING_CONFIRMATION:
          content = <>
            <Modal.Header closeButton={showCloseButton}>
              <Modal.Title>Please confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.confirmationText}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.userCanceled}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.userConfirmed}>
                Confirm
              </Button>
            </Modal.Footer>
          </>
          break
        case ProcessState.WAITING_USER:
          content = <>
            <Modal.Header closeButton={showCloseButton}>
              <h2>{this.state.userInputSetup.title}</h2>
            </Modal.Header>
            {this.state.validationErrors ? <Alert variant="danger">Validation errors: {this.state.validationErrors.message}</Alert>: ''}
            {this.state.userInputSetup.component}</>
          break
        case ProcessState.VALIDATION:
        case ProcessState.EXECUTING:
          content = <BigSpinner></BigSpinner>
          break
        default: 
          break
      }
    }
    
    if(this.props.static) {
      return <Modal.Dialog>
        {content}
      </Modal.Dialog>
    } else {
      return <Modal show={true} onHide={this.props.completionCallback.bind(false)}>
        {content}
      </Modal>
    }
  }
}

export default OperationWorkflowModal