import React, {Component} from 'react'
import {ColumnDefinition} from 'common/components/DataTable';
import GenericList from 'common/components/GenericList';

class CategoriesList extends Component {

    render() {
        
        const definitions = [
            new ColumnDefinition("Name", "name")
        ]

        return (
            <GenericList title="Categories" columns={definitions} endpoint="/categories" />
        )
    }
}

export default CategoriesList