import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Col, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import * as yup from 'yup'
import * as Types from 'common/utils/Types'
import { Redirect } from 'react-router-dom'


/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 * - entryId: if it's a number, it will edit the fund with this ID. If it's null, it will create a new fund
 */
class EditFundWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {

        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}></Redirect>
        }

        const me = this
        const loadingStep = (parameters) => {
            let dataToFetch = {
                "currencies": API.get('/currencies'),
                "institutions": API.get('/institutions')
            }
            if(this.props.entryId !== undefined) {
               dataToFetch.entry = API.get(`/funds/${this.props.entryId}`)
            }
            return API.multiple(dataToFetch)
            .then((r) => { 
                return {
                    currencies: r.currencies.results, 
                    entry: r["entry"],
                    institutions: r.institutions.results
                } 
            })
        }

        const userInputStep = (parameters, loading_result, values, errors, completion) => {
            const form = this.form(loading_result.currencies, loading_result.entry, loading_result.institutions, completion)
            return {component: form, title: this.props.entryId ? "Edit fund" : "New fund"}
        }
        const executionStep = (parameters, loading_result, user_input) => {
            if (this.props.entryId) {
                return API.put(`/funds/${this.props.entryId}`, user_input)
            } else {
                return API.post("/funds", user_input)
            }
        }
        const completionCallback = (execution_result) => {
            if(Types.isString(me.props.onSuccess)) {
                me.setState({redirect: me.props.onSuccess})
            } else { // is a callback
                me.props.onSuccess(execution_result && execution_result.data)
            }

        }

        return <OperationWorkflowModal
            static={this.props.static}
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            executionStep={executionStep}
            completionCallback={completionCallback}
        />
    }   

    form(loaded_currencies, previousValues, institutions, completion) {

        const parseForm = (values) => {
            let data = {
                currencyId: values.currency,
                name: values.name,
                amount: values.amount,
                usedForNewMovements: values.usedForNewMovements,
                deleted: false,
                shortName: values.shortName
            }
            if (values.institution !== "") {
                data.institution = values.institution
            }
            completion(data)
        }

        const schema = yup.object().shape({
            currency: yup.number(),
            name: yup.string().required(),
            amount: yup.number().required(),
            usedForNewMovements: yup.boolean(),
            shortName: yup.string().required(),
            institution: yup.string()
        })

        const currencies = loaded_currencies.map((item) => <option value={item.id} key={`cat${item.id}`}>{item.name}</option>);

        const initialValues = previousValues ? {
            currency: previousValues.currency.id,
            name: previousValues.name,
            amount: previousValues.amount,
            usedForNewMovements: previousValues.usedForNewMovements,
            shortName: previousValues.shortName,
            institution: previousValues.institution
        } : {
            currency: loaded_currencies[0].id,
            name: "",
            amount: "",
            usedForNewMovements: true,
            shortName: "",
            institution: ""
        }
        const institutionsOptions = institutions.map((item) => <option value={item} key={`inst_${item}`}>{item}</option>);

        return <>
        <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                
                <Form.Row>
                    <Form.Group as={Col} controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type='text'
                                name='name' 
                                placeholder="Name" 
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formShortName">
                            <Form.Label>Short name</Form.Label>
                            <Form.Control 
                                type='text'
                                name='shortName' 
                                placeholder="Short name" 
                                value={values.shortName}
                                onChange={handleChange}
                                isInvalid={!!errors.shortName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.shortName}</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                
                <Form.Row>

                    <Form.Group as={Col} controlId="formAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control 
                            type='text'
                            name='amount' 
                            placeholder="1000.00" 
                            value={values.amount}
                            onChange={handleChange}
                            isInvalid={!!errors.amount}
                        />
                        <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formCurrency">
                        <Form.Label>Currency</Form.Label>
                        <Form.Control 
                            as='select'
                            name='currency' 
                            value={values.currency}
                            onChange={handleChange}
                            isInvalid={!!errors.currency} 
                        >
                            {currencies}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.currency}</Form.Control.Feedback>
                    </Form.Group>

                </Form.Row>
                <Form.Group controlId="formUsedForNewMovements">
                    <Form.Check  
                        type='checkbox'
                        label='Use for new movements'
                        name='usedForNewMovements' 
                        checked={values.usedForNewMovements}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="formInstitution">
                        <Form.Label sm="2">Institution</Form.Label>
                        <Form.Control 
                            as='select'
                            name='institution' 
                            value={values.institution}
                            onChange={handleChange}
                            isInvalid={!!errors.institution} 
                        >
                            <option></option>
                        {institutionsOptions}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.institution}</Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }
}

export default EditFundWorkflow