import React, {Component} from 'react'
import EditMovementWorkflow from 'application/movements/EditMovementWorkflow.js'

class AddMovement extends Component {

    render() {
        return <EditMovementWorkflow onSuccess="/movements" static={true} />
    }   
}

export default AddMovement