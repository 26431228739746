import {Modal, Button, Form} from 'react-bootstrap'
import React, {Component} from 'react'

/**
 * Props:
 * - plans: array of plans [{id: ..., validFrom: ...}]
 * - onHide: callback()
 * - onCreate: callback()
 * - onSelect: callback(plan)
 * - currentPlan: YYYY-MM
 */

class PlanSelectionModal extends Component {

    constructor() {
        super()
        this.state = {
            selectedPlan: null
        }
    }

    componentDidMount() {
        const selectedPlan = this.props.plans.findIndex(p => p.id === this.props.currentPlan.id)
        this.setState({selectedPlan: selectedPlan})
    }

    planSelected(e) {
        const value = e.target.value
        this.setState({selectedPlan: value})
        const plan = this.props.plans[value]
        this.props.onSelect(plan)
    }

    render() {
        return <Modal show={true} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Select plan</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => e.preventDefault()}> 
                            <Form.Group>
                                <Form.Label>Validity</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    onChange={(e) => this.planSelected(e)}
                                    value={this.state.selectedPlan || ""}
                                    >
                                    {this.props.plans.map((plan, i) => {
                                        return <option key={i} value={i}>{plan.validFrom} to {plan.validTo}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Button variant="link" onClick={this.props.onCreate}>Create new</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
    }
}

export default PlanSelectionModal