import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Col, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import * as yup from 'yup'
import * as Types from 'common/utils/Types'
import { Redirect } from 'react-router-dom'

const LastCurrency = "expected-movements-last-currency"

/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 * - entryId: if it's a number, it will edit the entity with this ID. If it's null, it will create a new entity
 * - currentPlanId: the id of the current plan
 */
class EditExpectedMovementWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {

        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}></Redirect>
        }

        const me = this
        const loadingStep = (parameters) => {
            let dataToFetch = {
                "currencies": API.get('/currencies'),
                "categories": API.get('/categories'),
                "plans": API.get('/budget-plan'),
                "lastCurrency": API.getVariable(LastCurrency, "int")
            }
            if(this.props.entryId !== undefined) {
               dataToFetch.entry = API.get(`/expected-movements/${this.props.entryId}`)
            }
            return API.multiple(dataToFetch)
            .then((r) => { 
                return {
                    currencies: r.currencies.results, 
                    entry: r["entry"],
                    categories: r.categories.results,
                    plans: r.plans.results,
                    lastCurrency: r.lastCurrency.value
                } 
            })
        }

        const userInputStep = (parameters, loading_result, values, errors, completion) => {
            const form = this.form(loading_result, completion)
            return {component: form, title: this.props.entryId ? "Edit expected movement" : "New expected movement"}
        }
        const executionStep = (parameters, loading_result, user_input) => {
            let requests = {
                "var": API.setVariable(LastCurrency, user_input.currencyId, "int")
            }
            if (this.props.entryId) {
                requests["insert"] = API.put(`/expected-movements/${this.props.entryId}`, user_input)
            } else {
                requests["insert"] =  API.post("/expected-movements", user_input)
            }
            return API.multiple(requests)
        }
        const completionCallback = (execution_result) => {
            if(Types.isString(me.props.onSuccess)) {
                me.setState({redirect: me.props.onSuccess})
            } else { // is a callback
                me.props.onSuccess(execution_result && execution_result.data)
            }

        }

        return <OperationWorkflowModal
            static={this.props.static}
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            executionStep={executionStep}
            completionCallback={completionCallback}
        />
    }   

    form(loading_result, completion) {

        const parseForm = (values) => {
            completion({
                ...values,
                amount: values.amount.trim()
            })
        }

        const previousValues = loading_result.entry
        const schema = yup.object().shape({
            summary: yup.string().required().trim(),
            amount: yup.number().required(),
            repeatsAfterDays: yup.number().required(),
            categoryId: yup.number().required(),
            currencyId: yup.number().required(),
            granted: yup.boolean()
        })


        const currencies = loading_result.currencies.map((item) => <option value={item.id} key={`cur${item.id}`}>{item.name}</option>);
        const categories = loading_result.categories.map((item) => <option value={item.id} key={`cat${item.id}`}>{item.name}</option>);
        const repeatValues = [
            {value: 1, label: "Daily"},
            {value: 7, label: "Weekly"}, 
            {value: 14, label: "Every two weeks"},
            {value: 30, label: "Monthly"}
        ]
        const repeatOptions = repeatValues.map((item) => <option value={item.value} key={`rep${item.value}`}>{item.label}</option>);


        const initialValues = previousValues ? {
            summary: previousValues.summary,
            amount: previousValues.amount,
            repeatsAfterDays: previousValues.repeatsAfterDays,
            categoryId: previousValues.category.id,
            currencyId: previousValues.currency.id,
            granted: previousValues.granted,
            budgetPlanId: previousValues.budgetPlan.id
        } : {
            summary: "",
            amount: "",
            repeatsAfterDays: repeatValues[0].value,
            currencyId: loading_result.lastCurrency || loading_result.currencies[0].id,
            categoryId: loading_result.categories[0].id,
            granted: false,
            budgetPlanId: this.props.currentPlanId
        }

        return <>
        <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                
                <Form.Group controlId="formSummary">
                        <Form.Label>Summary</Form.Label>
                        <Form.Control 
                            type='text'
                            name='summary' 
                            placeholder="Summary" 
                            value={values.summary}
                            onChange={handleChange}
                            isInvalid={!!errors.summary}
                        />
                        <Form.Control.Feedback type="invalid">{errors.summary}</Form.Control.Feedback>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="formAmount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control 
                                type='text'
                                name='amount' 
                                placeholder="-10.00" 
                                value={values.amount}
                                onChange={handleChange}
                                isInvalid={!!errors.amount}
                            />
                            <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formCurrency">
                        <Form.Label>Currency</Form.Label>
                        <Form.Control 
                            as='select'
                            name='currencyId' 
                            value={values.currencyId}
                            onChange={handleChange}
                            isInvalid={!!errors.currency} 
                        >
                        {currencies}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formRepeat">
                    <Form.Label sm="2">Repeats every</Form.Label>
                    <Form.Control 
                        as='select'
                        name='repeatsAfterDays' 
                        value={values.repeatsAfterDays}
                        onChange={handleChange}
                        isInvalid={!!errors.repeatsAfterDays} 
                    >
                    {repeatOptions}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formCategory">
                    <Form.Label sm="2">Category</Form.Label>
                    <Form.Control 
                        as='select'
                        name='categoryId' 
                        value={values.categoryId}
                        onChange={handleChange}
                        isInvalid={!!errors.categoryId} 
                    >
                    {categories}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formGranted">
                    <Form.Check  
                        type='checkbox'
                        label='Exact amount guaranteed for each month'
                        name='granted' 
                        checked={values.granted}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }
}

export default EditExpectedMovementWorkflow