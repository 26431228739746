import React, {Component} from 'react'
import { Pie } from 'react-chartjs-2';
import Utils from 'common/Utils';
import Colors from 'common/utils/Colors';

/** Props
 * - data: the data to display, with the schema:
 *      {
 *          "EUR": {
 *              [
 *                  {
 *                      "category": {
 *                          <metadata>
 *                      },
 *                      "monthlyTotal": "100.00"
 *                  },
 *                  ...
 *              ] 
 *          },
 *          "USD": {...},
 *          ...
 *      }
 */

class ExpenseByCategoryChart extends Component {

    render() {

        const currencies = Object.keys(this.props.data)
        return <>
        {currencies.map(curr => {
            const categories = this.props.data[curr]
            const data = {
                labels: categories.map(c => c.category.name),
                datasets: [
                  {
                    label: curr,
                    data: categories.map(c => c.amount),
                    borderWidth: 1,
                    backgroundColor: categories.map(c => Colors.bySign(c.amount))
                  },
                ],
              };
            return <Pie 
                key={curr} 
                data={data}
                options={{
                    cutout: "50%", 
                    offset: 5,
                    plugins: {
                        title: {
                            display: true,
                            text: curr
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return `${context.label}: ${Utils.formatMoney(context.parsed)} ${curr}`
                                }
                            }
                        },
                        legend: {
                            display: false
                        }
                    }
                }} 
                />
        })}
        </>
    }   
}

export default ExpenseByCategoryChart