class DaysInterval {

    constructor(days) {
        this.days = days
        this.inText = DaysInterval.toDaysInString(days)
        this.perMonth = DaysInterval.toTimesPerMonth(days)
    }


    static toTimesPerMonth(days) {
        if(days === 30 || days === 31) {
            return 1
        }
    
        return (30 / days).toFixed(1)
    }

    static toDaysInString(days) {
        if (days === 30 || days === 31) {
            return "monthly"
        }
        if (days === 7) {
            return "weekly"
        }
        if (days === 14) {
            return "every two weeks"
        }
        return `every ${days} days`
    }
}

export default DaysInterval