import React, {Component} from 'react'
import {DataTable, ColumnDefinition, CellDefinition} from 'common/components/DataTable';
import Utils from 'common/Utils';
import * as Icons from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap';
import OperationWorkflowModal from 'common/components/OperationWorkflowModal';
import EditExpectedMovementWorkflow from 'application/budget/plan/EditExpectedMovementWorkflow';
import API from 'common/logic/API';

var _ = require('lodash');

/** Props
 * - currentPlan
 * - data: the monthly plan to display. Format: {total: {EUR: 10}, entries: {EUR: [{category: {...}, amount: 10}, ...] }}
 * - expectedMovementsByCurrency: the additional data that can be displayed for each entry (expected movements). Format: {EUR: [<movement>, ...], }}
 * - refreshCallback: callback() to refresh the data
 */

class ExpectedMovementsByCategoryTable extends Component {

    constructor() {
        super()
        this.state = {
            expandedRows: []
        }

        this.showCreateDialog = this.showCreateDialog.bind(this)
        this.showDeletionDialog = this.showDeletionDialog.bind(this)
        this.showEditDialog = this.showEditDialog.bind(this)
        this.hideDialogs = this.hideDialogs.bind(this)
    }

    refreshData() {
        this.props.refreshCallback()
    }

    showDeletionDialog(id, name) {
        this.setState({modal: 
            {
                type: "delete",
                id: id,
                name: name,
            }
        })
    }

    showCreateDialog() {
        this.setState({
            modal: {
                type: "create"
            }
        })
    }

    showEditDialog(id) {
        this.setState({
            modal: {
                type: "edit",
                id: id
            }
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    deletionModal(params) {
        const confirmationStep = (p, l) => { return `Delete expected movement "${params.name}"?`} 
        const executeStep = (p, l, u) => {return API.delete(`/expected-movements/${params.id}`) }

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }

        return <OperationWorkflowModal
            confirmationStep={confirmationStep}
            executionStep={executeStep}
            completionCallback={completion}
        />
    }

    creationOrEditModal(params) {

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }
        return <EditExpectedMovementWorkflow 
            currentPlanId={this.props.currentPlan.id}
            onSuccess={completion} 
            entryId={params.id}/>
    }

    render() {


        let modal = null
        if (this.state.modal) {
            switch(this.state.modal.type) {
                case "delete":
                    modal = this.deletionModal(this.state.modal)
                    break
                case "create":
                case "edit":
                    modal = this.creationOrEditModal(this.state.modal)
                    break;
                default:
            }
        }

        const currencies = Object.keys(this.props.data.total)
        return <>
        {modal}
        <Button variant="link" onClick={this.showCreateDialog}>Add new expected movement</Button>
        {currencies.map(curr => {

            const movementsByCategory = this.props.expectedMovementsByCurrency ?
                _.groupBy(this.props.expectedMovementsByCurrency[curr], e => e.category.id) : null
            return <React.Fragment key={curr}>
                {currencies.length > 1 ? <h2>{curr}</h2> : ''}
                <SingleCurrencyExpenseByCategoryTable 
                    currency={curr}
                    entries={this.props.data.entries[curr]}
                    total={this.props.data.total[curr]}
                    movementsByCategory={movementsByCategory}
                    onEdit={this.showEditDialog}
                    onDelete={this.showDeletionDialog}
                />
            </React.Fragment>
        })}
        </>
    }   
}

/**
 * Props:
 * - currency: the currency
 * - entries: the categories entries. Format: [{category: {...}, amount: 10}, ...]
 * - total: the overall total. Format: 10
 * - categoryLink: see `ExpenseByCategoryTable`
 * - movementsByCategory: the additional data that can be displayed for each entry (expected movements). Format: {<categoryId>: [<movement>, ...], }
 * - onEdit: callback(id) when a row should be edited
 * - onDelete: callback(id, name) when a row should be deleted
 * 
 */
class SingleCurrencyExpenseByCategoryTable extends Component {

    constructor() {
        super()
        this.state = {
            expandedRows: {} // <idx>: <boolean>
        }
    }

    expandCategory(i) {
        const expanded = this.state.expandedRows
        expanded[i] = true
        this.setState({expandedRows: expanded})
    }

    collapseCategory(i) {
        const expanded = this.state.expandedRows
        expanded[i] = false
        this.setState({expandedRows: expanded})

    }

    render() {

        const categoryCell = (category) => {
            let id = category.id
            let button = null
            let extension = null
            if(this.props.movementsByCategory) {
                const movements = this.props.movementsByCategory[id]
                const isOpen = this.state.expandedRows[id]
                const icon = isOpen ? <Icons.ChevronUp /> : <Icons.ChevronDown />
                const onClick = () => {
                    isOpen ? this.collapseCategory(id) : this.expandCategory(id)
                }
                button = <Button variant="link" onClick={onClick}>{icon}</Button>
                extension = isOpen && movements ? <div className="subtable-indent">
                    <ExpectedMovementsReducedTable 
                        movements={movements} 
                        onEdit={this.props.onEdit}
                        onDelete={this.props.onDelete}
                        />
                    </div>
                    : null
            }
            return <>
                    {button}
                    {category.name}
                    {' '}
                    {extension}
                </>
        }

        const sumByCurrency = [
            new CellDefinition(`TOTAL`, "td-total"),
            new CellDefinition(<>{Utils.formatMoney(this.props.total, true)} {this.props.currency}</>, "td-currency")
        ]

        const columnDefinitions = [
            new ColumnDefinition("Category", obj => categoryCell(obj.category)),
            new ColumnDefinition("Amount", obj =>  <>{Utils.formatMoney(obj.amount, true)} {this.props.currency}</>, "td-currency", "td-right")
        ]

        return <>
            <DataTable 
                columns={columnDefinitions} 
                rows={this.props.entries} 
                summaryRows={[sumByCurrency]}
            />
        </>
    }
}

/**
 * Props:
 * - movements: the data. Format: [<movement>, ...]
 * - onEdit: callback(id) when a row should be edited
 * - onDelete: callback(id, name) when a row should be deleted
 **/
class ExpectedMovementsReducedTable extends Component {
    
    render() {

        const columnDefinitions = [
            new ColumnDefinition("Entry", "summary"),
            new ColumnDefinition("Monthly amount", obj => <>{Utils.formatMoney(obj.monthlyAmount, true)} {obj.currency.name}</>, "td-currency", "td-right" ),
            new ColumnDefinition("Actions", obj => { 
                return <> 
                <Button variant="link" title="Edit"
                    onClick={() => this.props.onEdit(obj.id)}><Icons.Pencil /></Button> | 
                <Button variant="link" title="Delete"
                    onClick={() => this.props.onDelete(obj.id, obj.summary)} ><Icons.Trash /></Button></> 
            })
        ]

        return <DataTable 
            columns={columnDefinitions} 
            rows={this.props.movements}
        />
    }
}

export default ExpectedMovementsByCategoryTable