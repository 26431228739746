import React, {Component} from 'react'
import {Formik} from 'formik'
import * as yup from 'yup'
import {Button, Modal, Form, Col, Row} from 'react-bootstrap'


/**
 * A form to edit filter values
 * Props:
 * - currentFilters
 * - onSuccess
 */
class FilterModal extends Component {

    close() {
        this.props.onSuccess(null)
    }

    render() {

        return <>
        <Modal show={true} onHide={this.close.bind(this)}>
        <Modal.Header closeButton="true">
            <Modal.Title>Filter by date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.form(this.props.currentFilters, this.props.onSuccess)}
        </Modal.Body>
        </Modal>
        </>
        
    }

    form(previousValues, completion) {

        const schema = yup.object().shape({
            start_date: yup
                .date(),
            end_date: yup
                .date()
        })

        const initialValues = {
            start_date: previousValues ? previousValues.start_date || "" : "",
            end_date: previousValues ? previousValues.end_date || "" : ""
        }
        
        const today = `YYYY-MM-DD`

        return <>
        <Formik
            validationSchema={schema}
            onSubmit={completion}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                
                <Form.Group as={Row} className="mb-3" controlId="start_date">
                    <Form.Label column sm="2">Start date</Form.Label>
                    <Col sm="10">
                        <Form.Control 
                            type='text' 
                            name='start_date'
                            placeholder={today}
                            value={values.start_date}
                            onChange={handleChange}
                            isInvalid={!!errors.start_date} 
                            />
                        <Form.Control.Feedback type="invalid">{errors.start_date}</Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="end_date">
                    <Form.Label column sm="2">End date</Form.Label>
                    <Col sm="10">
                        <Form.Control 
                            type='text' 
                            name='end_date'
                            placeholder={today}
                            value={values.end_date}
                            onChange={handleChange}
                            isInvalid={!!errors.end_date} 
                            />
                        <Form.Control.Feedback type="invalid">{errors.end_date}</Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Button variant="primary" type="submit">Apply</Button>

            </Form>
            )}
        </Formik>
        </>
    }
}

export default FilterModal