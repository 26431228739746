import {Tabs, Tab, Button} from 'react-bootstrap'
import Title from 'common/components/Title'
import React, {Component} from 'react'
import ExpectedMovementsList from 'application/budget/plan/ExpectedMovementsList'
import MonthlyOutlook from 'application/budget/plan/MonthlyOutlook'
import MonthOfYear from 'common/logic/MonthOfYear'
import API from 'common/logic/API'
import BigSpinner from 'common/components/BigSpinner'
import * as Icons from 'react-bootstrap-icons'
import CreatePlanWorkflow from 'application/budget/plan/CreatePlanWorkflow'
import PlanSelectionModal from 'application/budget/PlanSelectionModal'
import Utils from 'common/Utils'

class Plan extends Component {

    constructor() {
        super()
        this.state ={}
    }

    calculateMonth() {
        const query = new URLSearchParams(window.location.search)
        const fromParam = query.get("month") || ""
        return MonthOfYear.parse(fromParam) || null
    }

    componentDidMount() {
        const month = this.calculateMonth()
        const me = this
        API.multiple({
            currentPlan: API.get(`/budget-plan/current`),
            plans: API.get(`/budget-plan`),
        }).then(response => {
            const plans = response.plans.results.map((p, i, arr) => {
                const next = i+1 < arr.length ? arr[i+1] : null
                return {
                    id: p.id,
                    validFrom: p.validFrom,
                    validTo: next ? next.validFrom : "current"
                } 
            })
            let currentPlan = undefined
            if(month) {
                let _month = month.toString()
                currentPlan = plans.find(p => p.validFrom.toString() === _month)
            } else {
                currentPlan = plans.find(p => p.id === response.currentPlan.id)
            }
            me.setState({
                currentPlan: currentPlan,
                plans: plans,
            })
        }).catch(error => {
            me.setState({error: error.message})
        })
    }

    showPlanSelection() {
        this.setState({
            modal : {
                type: "selectPlan"
            }
        })
    }

    showPlanCreation() {
        this.setState({
            modal : {
                type: "create"
            }
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    planSelectionDialog() {
        return <PlanSelectionModal 
            currentPlan={this.state.currentPlan}
            plans={this.state.plans} 
            onHide={() => this.hideDialogs()}
            onCreate={() => this.showPlanCreation()}
            onSelect={(plan) => {
                this.hideDialogs()
                this.setState({currentPlan: plan})
                Utils.pushUrlWithParams({month: plan.validFrom.toString()})
            }}
        />
    }

    planCreationModal() {

        return <CreatePlanWorkflow 
            onSuccess={(month) => {
                this.hideDialogs()
                this.setState({month: month})
            }}

        />
    }

    render() {

        if(!this.state.currentPlan) {
            return <><Title title={`Monthly budget plan`}
            />
            <BigSpinner /></>
        }

        let modal = null
        if(this.state.modal) {
            switch(this.state.modal.type) {
                case "selectPlan": 
                    modal = this.planSelectionDialog()
                    break
                case "create":
                    modal = this.planCreationModal()
                    break
                default:
            }
        }

        const subtitle = <>Valid from {this.state.currentPlan.validFrom} to {this.state.currentPlan.validTo}
            <Button variant="link" onClick={() => this.showPlanSelection()}><Icons.BoxArrowInUpRight /></Button></>
        return <>
            <Title title={`Monthly budget plan`}
            subtitle={subtitle}
            />


            {modal}
            <Tabs mountOnEnter={true} unmountOnExit={true}>
                <Tab 
                    eventKey="summary" 
                    title="Monthly outlook"
                    >
                        <MonthlyOutlook 
                            currentPlan={this.state.currentPlan}
                        />
                </Tab>
                <Tab 
                    eventKey="movements" 
                    title="All expected movements">
                        <ExpectedMovementsList 
                            currentPlan={this.state.currentPlan}
                        />
                        </Tab>
            </Tabs>
        </>
    }   
}

export default Plan