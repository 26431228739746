import React, {Component} from 'react'
import {ColumnDefinition, CellDefinition} from 'common/components/DataTable';
import BigSpinner from 'common/components/BigSpinner';
import {DataTable} from 'common/components/DataTable';
import FetchBehavior from "common/logic/FetchBehavior"
import Title from 'common/components/Title';
import Utils from "common/Utils.js"
import { Button } from 'react-bootstrap';
import API from 'common/logic/API'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal';
import EditFundWorkflow from 'application/funds/EditFundWorkflow';
import AdjustFundWorkflow from 'application/funds/AdjustFundWorkflow';
import * as Icon from 'react-bootstrap-icons';

var _ = require('lodash')

class FundsList extends Component {

    fundsLink(obj) {    
        return <a href={`/movements?&fund=${obj.id}`}>{obj.name}</a>
    }

    constructor(props) {
        
        super(props)
        this.state = {
            results: null,
            modal: false
        }
        
        this.showCreateDialog = this.showCreateDialog.bind(this)
        this.showDeletionDialog = this.showDeletionDialog.bind(this)
        this.showAdjustDialog = this.showAdjustDialog.bind(this)
        this.hideDialogs = this.hideDialogs.bind(this)
    }

    componentDidMount() {
        this.refreshData()
    }

    columnDefinitions = [
        new ColumnDefinition("Name", obj =>  <>{this.fundsLink(obj)} [{obj.shortName}]</>),
        new ColumnDefinition("Amount", obj =>  <>{Utils.formatMoney(obj.amount, true)} {obj.currency.name}</>, "td-currency", "td-right"),
        new ColumnDefinition("Institution", "institution", "td-size-s"),
        new ColumnDefinition("User for new movements", obj =>  obj.usedForNewMovements ? "YES" : "NO", "td-size-s"),
        new ColumnDefinition("Actions", obj => { 
            return <>
            <Button variant="link" title="Adjust"
                onClick={this.showAdjustDialog.bind(this, obj.id, obj.name)}><Icon.ArrowsCollapse /></Button> | 
            <Button variant="link" title="Edit"
                onClick={this.showEditDialog.bind(this, obj.id)}><Icon.Pencil /></Button> | 
            <Button variant="link" title="Delete"
                onClick={this.showDeletionDialog.bind(this, obj.id, obj.name)} ><Icon.Trash /></Button></> 
        })
    ]

    render() {

        let modal = null
        if (this.state.modal) {
            switch(this.state.modal.type) {
                case "delete":
                    modal = this.deletionModal(this.state.modal)
                    break
                case "create":
                case "edit":
                    modal = this.creationOrEditModal(this.state.modal)
                    break;
                case "adjust":
                    modal = this.adjustModal(this.state.modal)
                    break;
                default:
            }
        }
    
        return (
            <>
                <Title title="Funds"/>
                {this.state.results && this.state.results.error && <p>Error!</p>}
                
                {!this.state.results || this.state.results.results === null ? <BigSpinner /> : 
                <><Button variant="link" onClick={this.showCreateDialog}>Add new</Button>{this.resultsUI()}</>}
                {modal}
                
            </>
        )
    }

    showDeletionDialog(id, name) {
        this.setState({modal: 
            {
                type: "delete",
                id: id,
                name: name,
            }
        })
    }

    showCreateDialog() {
        this.setState({
            modal: {
                type: "create"
            }
        })
    }

    showEditDialog(id) {
        this.setState({
            modal: {
                type: "edit",
                id: id
            }
        })
    }

    showAdjustDialog(id, name) {
        this.setState({modal: 
            {
                type: "adjust",
                id: id,
                name: name,
            }
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    refreshData() {
        this.setState({results: null})
        this.fetchBehavior = new FetchBehavior({
            endpoint: "/funds", 
            stateHolder: this
        })
        this.fetchBehavior.fetchFromURL()
    }

    deletionModal(params) {
        const confirmationStep = (p, l) => { return `Delete fund "${params.name}"?`} 
        const executeStep = (p, l, u) => {return API.delete(`/funds/${params.id}`) }

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }

        return <OperationWorkflowModal
            confirmationStep={confirmationStep}
            executionStep={executeStep}
            completionCallback={completion}
        />
    }

    creationOrEditModal(params) {

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }
        return <EditFundWorkflow onSuccess={completion} entryId={params.id}/>
    }

    adjustModal(params) {
        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }
        return <AdjustFundWorkflow onSuccess={completion} entryId={params.id}/>

    }

    resultsUI() {

        const nonDeleted = this.state.results.results.filter((obj) => { return !obj.deleted })
        return <DataTable columns={this.columnDefinitions} 
                    rows={nonDeleted}
                    summaryRows={this.sumByCurrency()}
                    />
    }

    sumByCurrency() {
        if (!this.state.results || !this.state.results.results) {
            return null
        }

        function parseCents(x) {
            return parseInt(x.replace(".",""))
        }

        const nonDeleted = this.state.results.results.filter((obj) => { return !obj.deleted })
        const movementsByCurrency = _.groupBy(nonDeleted, r => r.currency.name)
        const sumByCurrency =_.mapValues(movementsByCurrency, 
            list => _.reduce(list, (sum, e) => sum + parseCents(e.amount), 0))

        const currenciesToDisplay = _.toPairs(sumByCurrency) //_.filter(_.toPairs(sumByCurrency), e => e[1] 0)

        return currenciesToDisplay.map(e => [
            new CellDefinition(`TOTAL`, "td-total"),
            new CellDefinition(<>{Utils.formatMoney(e[1] / 100, true)} {e[0]}</>, "td-currency")
        ])
    }
}

export default FundsList