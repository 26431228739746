import React, {Component} from 'react'
import {Nav, Navbar, NavDropdown, Button, Modal} from 'react-bootstrap'
import { Auth } from '../logic/Auth';

class Header extends Component {

    render() {
    
        return (
            <Navbar bg="light" expand="lg">
            <Navbar.Brand href="">Fin83</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                {this.props.username ? <NavigationBar /> : null}
            </Navbar.Collapse>
            </Navbar>
        )
    }
}

class NavigationBar extends Component {

    constructor() {
        super()
        this.state = {}
    }

    logout() {
        this.hidePopups()
        Auth.clearCredentials()
        window.location.href = "/"
    }

    confirmLogout() {
        this.setState({modal: "logout"})
    }

    hidePopups() {
        this.setState({modal: null})
    }

    render() {

        let modal = null
        if(this.state.modal === "logout") {
            modal = <Modal show={true} onHide={() => this.hidePopups()} closeButton>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm logout?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="danger" onClick={() => this.logout()}>Confirm logout</Button>{' '}
                    <Button variant="secondary" onClick={() => this.hidePopups()}>Cancel</Button>
                </Modal.Body>
            </Modal>
        }

        return <>
            {modal}
            <Nav className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <NavDropdown title="Movements" id="basic-nav-dropdown-movements">
                    <NavDropdown.Item href="/movements/add">Add</NavDropdown.Item>
                    <NavDropdown.Item href="/movements">List</NavDropdown.Item>
                    <NavDropdown.Item href="/movements/import">Import</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/classification-rules">Automatic classification rules</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Budget" id="basic-nav-dropdown-budget">
                    <NavDropdown.Item href="/report">Monthly report</NavDropdown.Item>
                    <NavDropdown.Item href="/plan">Budget planning</NavDropdown.Item>
                    <NavDropdown.Item href="/trend">Trend</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/funds">Funds</Nav.Link>
                <NavDropdown title="Account" id="basic-nav-dropdown-account">
                    <NavDropdown.Item href="/settings">Settings</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => this.confirmLogout()}>Logout</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </>
    }
}

export default Header