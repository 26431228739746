import Config from "config"
import { Auth } from "common/logic/Auth";
const axios = require('axios');

class API {

    static request(params, authenticated = true) {
        // auth
        if (authenticated) {
            const credentials = Auth.getCredentials()
            params.auth = {
                username: credentials.username,
                password: credentials.password
            }
        }
        // Construct URL
        if (!params.url.startsWith("https://") || !params.url.startsWith("http://")) {
            const urlSeparator = params.url.startsWith("/") ? "" : "/"
            params.url = Config.apiHost + urlSeparator + params.url
        }
        return axios(params)
    }

    static post(url, data, urlParams = null) {
        let params = {method: 'post', url: url, data: data}
        if (urlParams) {
            params.params = urlParams
        }
        return this.request(params)
    }

    static postFile(url, file, urlParams = null) {
        const data = new FormData();
        data.append(
            "myFile",
            file,
            file.name
        )
        let params = {method: 'post', url: url, data: data}
        if (urlParams) {
            params.params = urlParams
        }
        params.headers = {
            'content-type': 'multipart/form-data'
        }
        return this.request(params)
    }

    static put(url, data, urlParams = null) {
        let params = {method: 'put', url: url, data: data}
        if (urlParams) {
            params.params = urlParams
        }
        return this.request(params)
    }

    static get(url, urlParams = null) {
        let params = {method: 'get', url: url}
        if (urlParams) {
            params.params = urlParams
        }
        return this.request(params)
    }

    static delete(url, urlParams = null) {
        let params = {method: 'delete', url: url}
        if (urlParams) {
            params.params = urlParams
        }
        return this.request(params)
    }

    static paginate(url, urlParams, start=0, limit=50) {
        if (!urlParams) {
            urlParams = {}
        }
        urlParams.start = start
        urlParams.limit = limit
        this.get(url, urlParams)
    }

    /**
     * Performs multiple requests, stores the response of each one in a map from label to result
     * it fails if any of the requests fails. Fails if any of them fails
     * 
     * Param:
     * - mapping: an object mapping {key: promises}
     */
    static multiple(mapping) {
        return Promise.all(Object.entries(mapping).map((e) => e[1].then(response => [e[0], response.data])))
        .then(response =>{
            let accumulator = {}
            response.forEach((r) => {
                accumulator[r[0]] = r[1]
            })
            return accumulator
        })
    }

    static getVariable(name, type) {
        return API.get(`/variables/${type}/${name}`)
    }
    
    static setVariable(name, value, type = undefined) {
        if(!type) {
            if (value === parseInt(value, 10)) {
                type = "int"
            } else {
                type = "string"
            }
        }
        return API.put(`/variables/${type}/${name}`, {value: value})
    }
}

export default API