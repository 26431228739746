import React, {Component} from 'react'
import { Alert, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'

/**
 * Props:
 * - filters: map of applied filters (name -> value)
 * - lookup: map of (name -> (id -> value))
 * - onUpdateFilters: callback(newFilters) when filters are modified
 * - addFiltersCallback: callback() when new filters should be added (e.g. to show a popup)
 * - translations: map of name to display text (name -> text)
 */
class FilterBox extends Component {
    
    constructor(props) {
        super(props)
        this.removeFilters = this.removeFilters.bind(this)
    }

    removeFilters(keysToRemove) {
        let newFilters = {...this.props.filters}
        keysToRemove.forEach((e) => { delete newFilters[e]})
        if(this.props.onUpdateFilters) {
            this.props.onUpdateFilters(newFilters)
        }
    }
    
    render() {
        const filters = Object.entries(this.props.filters).filter((e, i) => {
            const [, value] = e
            return typeof value !== 'undefined' && value !== null
        })
        if (!filters || filters.length === 0) {
            if(this.props.addFiltersCallback) {
                return <p><a href="#" 
                onClick={this.props.addFiltersCallback.bind(this)}><Icon.CalendarCheckFill /> Filter by dates</a></p>
            } else {
                return null
            }
        }

        const filtersUI = filters.map(e => { 
            const [name, value] = e
            const lookup = this.props.lookup[name]
            const valueToDisplay = lookup ? lookup[value] : null
            const nameToDisplay = this.props.translations ? this.props.translations[name] : null
            return <span className="filter-card" key={name}>
                        {nameToDisplay || name}: {valueToDisplay ? valueToDisplay.name : value}
                        <Button
                            key={name}
                            variant="link"
                            onClick={this.removeFilters.bind(this, [name])}
                        ><Icon.XCircle /></Button>
                    </span>
        })
        const addFiltersButton = this.props.addFiltersCallback 
        ? <Button variant="link" onClick={this.props.addFiltersCallback}><Icon.CalendarCheckFill /></Button>
        : null

        return <Alert variant="info" className="filterbox">Filters: {filtersUI} {addFiltersButton}</Alert>
    }
}

export default FilterBox