import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Col, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import Utils from "common/Utils"
import * as yup from 'yup'
import * as Types from 'common/utils/Types'
import { Redirect } from 'react-router-dom'


/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 * - entryId: fund ID to adjust
 */
class AdjustFundWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {

        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}></Redirect>
        }

        const me = this
        const loadingStep = (parameters) => {
            let dataToFetch = [
                API.get('/categories').then(response => { return response.data["results"]}),
                API.get(`/funds/${this.props.entryId}`).then(response => {return response.data})
            ]
            return Promise.all(dataToFetch)
            .then((arr) => { 
                return {categories: arr[0], entry: arr[1]} 
            })
        }

        const userInputStep = (parameters, loading_result, values, errors, completion) => {
            const form = this.form(loading_result.categories, loading_result.entry, completion)
            return {component: form, title: `Adjust fund ${loading_result.entry.name}`}
        }
        const executionStep = (parameters, loading_result, user_input) => {
            return API.post(`/funds/adjust/${this.props.entryId}`, user_input)
        }
        const completionCallback = (execution_result) => {
            if(Types.isString(me.props.onSuccess)) {
                me.setState({redirect: me.props.onSuccess})
            } else { // is a callback
                me.props.onSuccess(execution_result && execution_result.data)
            }

        }

        return <OperationWorkflowModal
            static={this.props.static}
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            executionStep={executionStep}
            completionCallback={completionCallback}
        />
    }   

    form(loaded_categories, fund, completion) {

        const parseForm = (values) => {
            completion({
                reason: values.reason,
                date: values.date,
                newAmount: values.amount,
                categoryId: values.category
            })
        }

        const schema = yup.object().shape({
            reason: yup.string().required(),
            amount: yup.number().required(),
            date: yup.date().required(),
            category: yup.number().required()
        })

        const category_options = loaded_categories.map((item) => <option value={item.id} key={`cat${item.id}`}>{item.name}</option>);
        const today = Utils.formatDate(new Date())

        const initialValues = {
            date: today,
            reason: "Fund adjustment",
            amount: fund.amount,
            category: loaded_categories[0].id
        }

        return <>
        <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>

                <Form.Group controlId="formReason">
                    <Form.Label column sm="2" >Reason</Form.Label>
                    <Form.Control
                        type='text'
                        name='reason' 
                        placeholder="Reason" 
                        value={values.reason}
                        onChange={handleChange}
                        isInvalid={!!errors.reason}
                        />
                    <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} controlId="formAmount">
                        <Form.Label column sm="2">Amount</Form.Label>
                        <Form.Control 
                            type='text'
                            name='amount' 
                            placeholder="-10.00" 
                            value={values.amount}
                            onChange={handleChange}
                            isInvalid={!!errors.amount}
                        />
                        <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formDate">
                        <Form.Label column sm="2">Date</Form.Label>
                        <Form.Control 
                            type='text'
                            name='date' 
                            placeholder="2020-12-31" 
                            value={values.date}
                            onChange={handleChange}
                            isInvalid={!!errors.date} 
                        />
                        <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formCategory">
                    <Form.Label column sm="2">Category</Form.Label>
                    <Form.Control 
                        as='select'
                        name='category' 
                        value={values.category}
                        onChange={handleChange}
                        isInvalid={!!errors.category} 
                    >
                        {category_options}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }
}

export default AdjustFundWorkflow