import React, {Component} from 'react'
import {ColumnDefinition} from 'common/components/DataTable'
import GenericList from 'common/components/GenericList'
import {Link, Redirect} from 'react-router-dom'
import Utils from "common/Utils.js"
import { Button } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import EditClassificationRuleWorkflow from 'application/categories/EditClassificationRuleWorkflow'
import API from 'common/logic/API'


class ClassificationRulesList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            dataKey: Date.now() 
        }
        this.showCreateDialog = this.showCreateDialog.bind(this)
        this.showDeletionDialog = this.showDeletionDialog.bind(this)
        this.hideDialogs = this.hideDialogs.bind(this)
    
    }

    showDeletionDialog(id, name) {
        this.setState({modal: 
            {
                type: "delete",
                id: id,
                name: name,
            }
        })
    }

    showCreateDialog() {
        this.setState({
            modal: {
                type: "create"
            }
        })
    }

    showEditDialog(id) {
        this.setState({
            modal: {
                type: "edit",
                id: id
            }
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    refreshData() {
        this.setState({dataKey: Date.now()})
    }

    deletionModal(params) {
        const confirmationStep = (p, l) => { return `Delete rule "${params.name}"?`} 
        const executeStep = (p, l, u) => {return API.delete(`/classification-rules/${params.id}`) }

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }

        return <OperationWorkflowModal
            confirmationStep={confirmationStep}
            executionStep={executeStep}
            completionCallback={completion}
        />
    }

    creationOrEditModal(params) {

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.setState({dataKey: Date.now()})
            }
        }
        return <EditClassificationRuleWorkflow onSuccess={completion} entryId={params.id}/>
    }

    render() {
        

        if(this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }

        let modal = null
        switch(this.state.modal.type) {
            case "delete": 
                modal = this.deletionModal(this.state.modal);
                break;
            case "create":
            case "edit":
                modal = this.creationOrEditModal(this.state.modal);
                break;
            default:
        }

        const linkToCategory = obj => {
            const url = Utils.urlWithParameters(this.props.url, this.props.query, {category: obj.category.id})
            return <Link to={url}>{obj.category.name}</Link>
        }

        const definitions = [
            new ColumnDefinition("Name", "name"),
            new ColumnDefinition("Category", linkToCategory),
            new ColumnDefinition("Actions", obj => { 
                return <>
                <Button variant="link" title="Edit"
                    onClick={this.showEditDialog.bind(this, obj.id)}><Icon.Pencil /></Button> | 
                <Button variant="link" title="Delete"
                    onClick={this.showDeletionDialog.bind(this, obj.id, obj.name)} ><Icon.Trash /></Button></> 
            })
        ]

        return <>
            {modal}
            <GenericList 
            title="Classification rules" 
            subtitle="These rules are applied to classify movements when imported from bank statements"
            columns={definitions} 
            beforeTable={<Button variant="link" onClick={this.showCreateDialog}>Add new</Button>}
            dataKey={this.state.dataKey}
            endpoint="/classification-rules" 
            />
        </>
    }
}

export default ClassificationRulesList